<div class="row text-center">
    <div class="col-12">
        <img src="../assets/H.png" class="img-fluid" alt="H" width="250">
    </div>
    <div class="col-12 my-4">
        <form>
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-lg" [(ngModel)]="rumbo" name="rumbo"
                    placeholder="Rumbo" aria-describedby="rumbo-description" autofocus>
                <span class="input-group-text" id="rumbo-description">º</span>
            </div>
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-lg" [(ngModel)]="distancia" name="distancia"
                    placeholder="Distancia" aria-describedby="distancia-description">
                <span class="input-group-text" id="distancia-description">millas</span>
            </div>
            <button *ngIf="rumbo && distancia" type="button" class="btn btn-danger btn-lg" (click)="calcular()"
                title="Abrir en Google Maps">Go!</button>
        </form>
    </div>
</div>
