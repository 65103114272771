import { Component } from '@angular/core';

@Component({
  selector: 'app-rumbo-helicopteros',
  templateUrl: './rumbo-helicopteros.component.html',
  styleUrls: ['./rumbo-helicopteros.component.scss']
})
export class RumboHelicopterosComponent {
    distancia: number; // Millas
    rumbo: number; // Grados
    resultado: string;

    calcular(): void {
        const latitudSalida = this.convertDMSToDD(42, 48); // Latitud Miluce 42º 48' N
        const longitudSalida = this.convertDMSToDD(1, 40, 0, 'W'); // Longitud Miluce 1º 40' W

        // 1. Diferencia de latitud
        let diferenciaLatitud =
            this.distancia * Math.cos(this.toRadians(this.rumbo)); // Distancia por coseno del rumbo en radianes
        diferenciaLatitud = this.convertDMSToDD(diferenciaLatitud, 0);

        // 2. Latitud de llegada
        const latitudLlegada = latitudSalida + diferenciaLatitud / 60; // Latitud de salida decimal + diferencia de latitud decimal (grados)

        // 3. Apartamiento
        const apartamiento =
            this.distancia * Math.sin(this.toRadians(this.rumbo));

        // 4. Latitud media
        const latitudMedia = (latitudSalida + latitudLlegada) / 2; // Latitud media decimal (grados)

        // 5. Diferencia de longitud
        const diferenciaLongitud =
            apartamiento / Math.cos(this.toRadians(latitudMedia));

        // 6. Longitud de llegada
        const longitudLlegada = longitudSalida + diferenciaLongitud / 60; // Es negativo porque oeste tiene signo negativo

        window.location.href =
            'https://www.google.es/maps/@' +
            latitudLlegada +
            ',' +
            longitudLlegada +
            ',3000m/data=!3m1!1e3';
    }

    // Convert DMS format to decimal format
    private convertDMSToDD(
        degrees: number,
        minutes: number,
        seconds: number = 0,
        direction?: string
    ) {
        var dd = degrees + minutes / 60 + seconds / (60 * 60);

        if (direction == 'S' || direction == 'W') {
            dd = dd * -1;
        } // Don't do anything for N or E

        return dd;
    }

    // Convert from degrees to radians
    private toRadians = function (degrees: number): number {
        return (degrees * Math.PI) / 180;
    };
}
