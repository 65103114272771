import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    ngOnInit(): void {
        this.requestNotificationPermission();
    }

    private requestNotificationPermission(): void {
        if (window.Notification) {
            // request permission from user
            Notification.requestPermission().then((np: NotificationPermission) => {
                if(np === 'granted') {
                    // show notification here
                } else {
                    console.log('User blocked notifications.');
                }
            }).catch((err) => {
                console.error(err);
            });
        }
    }
}
