import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { RumboHelicopterosComponent } from './components/rumbo-helicopteros/rumbo-helicopteros.component';
import { I18nPluralPipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        MainLayoutComponent,
        RumboHelicopterosComponent,
    ],
    imports: [BrowserModule, AppRoutingModule, FormsModule, HttpClientModule, HttpClientJsonpModule, NgbModule],
    providers: [I18nPluralPipe],
    bootstrap: [AppComponent],
})
export class AppModule {}
